/* Comfortaa */
/* Light 300 */
@font-face {
	font-family: 'Comfortaa';
	src:
		url('../../assets/fonts/Comfortaa/Comfortaa-Bold.woff2') format('woff2'),
		url('../../assets/fonts/Comfortaa/Comfortaa-Bold.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}
/* Regular 400 */
@font-face {
	font-family: 'Comfortaa';
	src:
		url('../../assets/fonts/Comfortaa/Comfortaa-Regular.woff2') format('woff2'),
		url('../../assets/fonts/Comfortaa/Comfortaa-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
/* Medium 500 */
@font-face {
	font-family: 'Comfortaa';
	src:
		url('../../assets/fonts/Comfortaa/Comfortaa-Medium.woff2') format('woff2'),
		url('../../assets/fonts/Comfortaa/Comfortaa-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}
/* SemiBold 600 */
@font-face {
	font-family: 'Comfortaa';
	src:
		url('../../assets/fonts/Comfortaa/Comfortaa-SemiBold.woff2') format('woff2'),
		url('../../assets/fonts/Comfortaa/Comfortaa-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}
/* Bold 700 */
@font-face {
	font-family: 'Comfortaa';
	src:
		url('../../assets/fonts/Comfortaa/Comfortaa-Bold.woff2') format('woff2'),
		url('../../assets/fonts/Comfortaa/Comfortaa-Bold.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}
